import React from 'react';
import styled from 'styled-components';
import Script from 'react-load-script';

const FrameContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin-top: 10px;
  overflow: hidden;
  height: 480px;
`;

const SideBar = () => (
  <section>
    <FrameContainer>
      <div id="fb-root"></div>
      <Script
        url="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v7.0"
      /*onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}*/
      />
      <div class="fb-page" data-href="https://www.facebook.com/thebranch.org.au/" data-tabs="timeline" data-width="500" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/thebranch.org.au/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/thebranch.org.au/">The Branch</a></blockquote></div>
    </FrameContainer>
  </section>
);

export default SideBar;

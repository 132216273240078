/* eslint-disable */
import React, { Component } from "react";
import GoogleMap from "../../models/GoogleMap";

class ContactInfo extends Component {
	render() {
		return (
			<section>
				<div
					id="top-content-region"
					className="top-content padding-top-15 padding-bottom-15 block-15 bg-color-grayLight1"
				>
					<div className="container">
						<div className="row">
							<div id="top-content-left-region" className="top-content-left col-xs-12 col-md-6 text-center-sm">
								<div id="page-title-block" className="page-title block">
									<h1>Contact Information</h1>
								</div>
							</div>

							<div
								id="top-content-right-region"
								className="top-content-right col-xs-12 col-md-6 text-right text-center-sm"
							>
								<div id="page-breadcrumbs-block" className="page-breadcrumbs block">
									<div className="breadcrumbs">
										<a href="/">Home</a>
										<span className="delimiter">›</span>
										<span title="" className="nolink">
											Connect
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="content-region">
					<div className="container">
						<div className="row">
							<div id="main-content-region" className="main-content col-xs-12">
								<div className="region region-content">
									<div id="block-system-main" className="block block-system">
										<div className="content">
											<div className="node node-page clearfix">
												<div className="col-md-8">
													<GoogleMap id="map_canvas" height="480px" mapWidth="480px" />
												</div>

												<div className="col-md-4 padding-top-xs-15">
													<p>
														<strong>Office Details:</strong>
														<br />
														Phone: 03 6344 3991 <br />
													</p>
													<p className="padding-top-15">
														<strong>Mailing Address:</strong>
														<br />
														P.O. Box 933
														<br />
														Kings Meadows TAS 7249
													</p>
													<p className="padding-top-15">
														<strong>Street Address:</strong>
														<br />
														20/2 Innocent Street
														<br />
														Kings Meadows TAS 7249
													</p>
													<p className="padding-top-15">
														Or{" "}
														<strong>
															<a
																title="Get in Touch"
																href="https://thebranch.elvanto.com.au/form/8a95e7d4-4b16-11e3-963d-e9c9319b7782"
																target="_blank"
																rel="noreferrer noopener"
															>
																Get in Touch
															</a>
														</strong>{" "}
														online.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default ContactInfo;

import React, { Component } from 'react';

import LatestSermon from './LatestSermon';
//import WhereToFindUs from './WhereToFindUs';
//import UpcomingEvents from './UpcomingEvents';
import Services from './Services';
import FacebookFeedContainer from './FacebookFeedContainer'

class HomePageContent extends Component {
  render() {
    return (
      <section>
        <div className="content-2 bg-color-white text-color-default" >
          <div className="container">
            <div className="row">
              <Services />
              <LatestSermon />
              <FacebookFeedContainer />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HomePageContent;

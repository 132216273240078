import React, { Component } from 'react';
import YouTube from 'react-youtube';


class TheGoodNews extends Component {
    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    render() {
        const opts = {
            height: '390',
            width: '100%',
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: 0
            }
        };
        return (
            <section>
                <div id="top-content-region" className="top-content padding-top-15 padding-bottom-15 block-15 bg-color-grayLight1">
                    <div className="container">
                        <div className="row">
                            <div id="top-content-left-region" className="top-content-left col-xs-12 col-md-6 text-center-sm">
                                <div id="page-title-block" className="page-title block">
                                    <h1>What is the Good News?</h1>
                                </div>
                            </div>

                            <div id="top-content-right-region" className="top-content-right col-xs-12 col-md-6 text-right text-center-sm">
                                <div id="page-breadcrumbs-block" className="page-breadcrumbs block">
                                    <div className="breadcrumbs">
                                        <a href="/">Home</a>
                                        <span className="delimiter">›</span>
                                        <span title="" className="nolink">What is the Good News?</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="content-region">
                    <div className="container">
                        <div className="row">
                            <div id="main-content-region" className="main-content col-xs-12">
                                <div className="region region-content">
                                    <div className="content">
                                        <div className="node node-page clearfix">
                                            <div className="content">
                                                <div className="clearfix post-189 page type-page status-publish hentry">
                                                    <p>The good news is that God has sent his Son, Jesus, into the world to rescue people by dying on the cross for them. Anyone who puts their trust in him can be saved from sin and death and the brokeness of our world.</p>
                                                    <p>The video below gives a 3-minute overview of the good news that the Bible teaches.</p>
                                                    <p>After that if you want to know more you can:</p>
                                                    <ul className="padding-bottom-15">
                                                        <li><strong><a title="Christianity Explored" href="/ChristianityExplored">Sign up for one of our Christianity Explored courses</a></strong></li>
                                                        <li><strong><a title="Visit" href="/Visit">Visit us one Sunday</a></strong></li>
                                                        <li><strong><a title="Get in Touch" href="https://thebranch.elvanto.com.au/form/8a95e7d4-4b16-11e3-963d-e9c9319b7782" target="_blank" rel="noreferrer noopener">Get in touch</a></strong></li>
                                                    </ul>
                                                    <YouTube
                                                        videoId="qjQLAay1HqM"
                                                        opts={opts}
                                                        onReady={this._onReady}
                                                    />
                                                </div>
                                            </div>



                                        </div>
                                    </div>  </div>



                            </div>




                        </div>
                    </div>
                </div>
            </section >
        );
    }
}

export default TheGoodNews;

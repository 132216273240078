/* eslint-disable */
import React, { Component } from 'react';
import AppleLogo from '../../../assets/Listen on Apple Podcasts.svg';
import RSSLogo from '../../../assets/Rss-feed-01.svg';


import _ from 'lodash'
import { decode } from 'he'
import { getFromDrupalAPI } from '../../../utils/fetchJSON';
import AllSermonsComponent from './AllSermonsComponent';

const SERMON_LIMIT = 1;

class Sermons extends Component {
  constructor() {
    super();
    this.state = {
      sermons: null,
      recentSeries: null,
      latestSermon: null
    }
  }

  UNSAFE_componentWillMount() {
    var that = this;

    getFromDrupalAPI('all_sermons_api?limit=' + SERMON_LIMIT, function (data) {
      that.setState({ sermons: data });
      that.setState({ latestSermon: data[0] });
    });

    getFromDrupalAPI('recent_series_api', function (data) {
      that.setState({ recentSeries: data });
    });

  }
  render() {

    if (!this.state.sermons || !this.state.latestSermon || !this.state.recentSeries) {
      var recentSeries = <div>Loading, please wait.</div>;
      var latestSermon = <div>Loading, please wait.</div>
    }
    else {
      var recentSeries = _.map(this.state.recentSeries, (series) => {
        return (
          <div key={_.uniqueId()} className="views-row views-row-1 views-row-odd views-row-first col-sm-3">

            <div className="views-field views-field-field-thumbnail-image">
              <div className="field-content">
                <a href={"/series/" + series.series_id}>
                  <img src={series.series_img} width="300" height="300" alt="" /></a>
              </div>
            </div>
            <div className="views-field views-field-title">
              <span className="field-content">
                <a href={"/series/" + series.series_id}>{decode(series.node_title)}</a>
              </span>
            </div>
          </div>

        )
      });

      var latestSermon = (<div className="views-row views-row-1 views-row-odd views-row-first views-row-last">
        <div className="views-field views-field-field-thumbnail-image">        <div className="field-content">
          <a href={`/sermon/${this.state.latestSermon.nid}`}><img src={this.state.latestSermon.sermon_img ? this.state.latestSermon.sermon_img : this.state.latestSermon.series_img} width="600" height="450" alt="" /></a></div>  </div>
        <div className="views-field views-field-title-1">
          <span className="field-content">{this.state.latestSermon.sermonseries ? <a href={'/series/' + this.state.latestSermon.series_id}>{decode(this.state.latestSermon.sermonseries)}</a> : ""}</span>  </div>
        <div>
          {this.state.latestSermon.node_title ? <span><a href={`/sermon/${this.state.latestSermon.nid}`}>{decode(this.state.latestSermon.node_title)}</a></span> : <span><a href={`/sermon/${this.state.latestSermon.nid}`}>Untitled</a></span>}  </div>
        <div className="views-field views-field-field-preacher">
          <div className="field-content">{decode(this.state.latestSermon.preacher)}</div>  </div>  </div>);

      var currentSeries = (<section><div className="views-field views-field-field-thumbnail-image">
        <div className="field-content">
          <a href={'/series/' + this.state.latestSermon.series_id}><img src={this.state.latestSermon.series_img} width="600" height="450" alt="" /></a>
        </div>  </div>
        <div className="views-field views-field-title">
          <span className="field-content"><a href={'/series/' + this.state.latestSermon.series_id}>{this.state.latestSermon.sermonseries}</a></span>  </div></section>)
    }

    return (
      <section>
        <div id="top-content-region" className="top-content padding-top-15 padding-bottom-15 block-15 bg-color-grayLight1">
          <div className="container">
            <div className="row">
              <div id="top-content-left-region" className="top-content-left col-xs-12 col-md-6 text-center-sm">
                <div id="page-title-block" className="page-title block">
                  <h1>Sermons</h1>
                </div>
              </div>

              <div id="top-content-right-region" className="top-content-right col-xs-12 col-md-6 text-right text-center-sm">
                <div id="page-breadcrumbs-block" className="page-breadcrumbs block">
                  <div className="breadcrumbs">
                    <a href="/">Home</a>
                    <span className="delimiter">›</span>
                    <span title="" className="nolink">Sermons</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="content-region">
          <div className="container">
            <div className="row">


              <div id="main-content-region" className="main-content col-xs-12">

                <div className="region region-content">

                  <div className="content">
                    <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                      <div className="field-items">
                        <div className="field-item even">
                          <div className="podcast-container">
                            <div className="col-xs-6 text-center">
                              <a href="https://podcasts.apple.com/au/podcast/the-branch-podcast/id1468055974" target="_blank" rel="noreferrer noopener"><img className="img img-responsive podcast-img" src={AppleLogo} alt="" /></a>
                            </div>
                            <div className="col-xs-6 text-center">
                              <a href="http://feeds.feedburner.com/thebranchorgau" target="_blank" rel="noreferrer noopener"><img className="img img-responsive podcast-img" src={RSSLogo} alt="" /></a>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                          <p className="padding-top-40">Every Sunday the sermon for the service gets recorded. You can listen to all of them here.<br />
                            You can also subscribe to our podcast using either iTunes or another podcast application using the Feedburner URL by clicking the images above.</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="content">
                    <div id="node-34" className="node node-page clearfix">
                      <div className="container">
                        <div className="region region-content-2">

                          <div id="block-views-sermon-series-block-1">

                            <h2 className="header-lightBlue text-center-sm">Recent Series</h2>

                            <div className="content">
                              <div className="view view-sermon-series view-id-sermon_series view-display-id-block_1 row">
                                <div className="view-content text-center">
                                  {recentSeries}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>





                      <div className="content block block-views">
                        <h2 className="padding-left-15">Sermons</h2>
                        <AllSermonsComponent />
                      </div>



                    </div>
                  </div>
                </div>  </div>

            </div>

          </div> {/* /row */}
        </div> {/* /container */}


      </section >
    );
  }
}

export default Sermons;

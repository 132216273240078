/* eslint-disable */
import React, { Component } from 'react';
import YouTube from 'react-youtube';

class ExploreChristianity extends Component {
    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    render() {
        const opts = {
            height: '360',
            width: '100%',
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: 0
            }
        };
        return (
            <section>
                <div id="top-content-region" className="top-content padding-top-15 padding-bottom-15 block-15 bg-color-grayLight1">
                    <div className="container">
                        <div className="row">
                            <div id="top-content-left-region" className="top-content-left col-xs-12 col-md-6 text-center-sm">
                                <div id="page-title-block" className="page-title block">
                                    <h1>Christianity Explored</h1>
                                </div>
                            </div>

                            <div id="top-content-right-region" className="top-content-right col-xs-12 col-md-6 text-right text-center-sm">
                                <div id="page-breadcrumbs-block" className="page-breadcrumbs block">
                                    <div className="breadcrumbs">
                                        <a href="/">Home</a>
                                        <span className="delimiter">›</span>
                                        <span title="" className="nolink">Connect</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="content-region">
                    <div className="container">
                        <div className="row">
                            <div id="main-content-region" className="main-content col-xs-12">
                                <div className="region region-content">

                                    <div className="content">

                                        <div className="clearfix">
                                            <p>We regularly run <b>Christianity Explored</b> courses to help people investigate who Jesus is and to hear about the good news. It’s a free course that runs for 7 weeks. We meet weekly for about an hour and a half in small or large group settings. In each session we share some food, watch a video clip and have time for questions and open discussion. To get the flavour of Christianity Explored, check out the following video or check out the <strong><a href="http://www.christianityexplored.org/what-is-christianity" target="_blank" rel="noreferrer noopener">Christianity Explored website</a></strong>.</p>
                                            <p>You are welcome to <strong><a href="https://thebranch.elvanto.com.au/form/aa420130-4b5c-11e3-963d-e9c9319b7782" target="_blank" rel="noreferrer noopener">sign up for a course</a> </strong>with a friend<strong> </strong>today.</p>
                                            <div className="col-md-12 padding-top-15">
                                                <YouTube
                                                    videoId="cTASHKTPQZ0"
                                                    opts={opts}
                                                    onReady={this._onReady}
                                                />
                                            </div>






                                        </div>
                                    </div>
                                </div>



                            </div>




                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ExploreChristianity;

import React, { Component } from 'react';
import Iframe from 'react-iframe';

class Calendar extends Component {

    render() {
        return (
            <section>
                <div id="top-content-region" className="top-content padding-top-15 padding-bottom-15 block-15 bg-color-grayLight1">
                    <div className="container">
                        <div className="row">
                            <div id="top-content-left-region" className="top-content-left col-xs-12 col-md-6 text-center-sm">
                                <div id="page-title-block" className="page-title block">
                                    <h1>Calendar</h1>
                                </div>
                            </div>

                            <div id="top-content-right-region" className="top-content-right col-xs-12 col-md-6 text-right text-center-sm">
                                <div id="page-breadcrumbs-block" className="page-breadcrumbs block">
                                    <div className="breadcrumbs">
                                        <a href="/">Home</a>
                                        <span className="delimiter">›</span>
                                        <span title="" className="nolink">Resources</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="content-region">
                    <div className="container">
                        <div className="row">
                            <div id="main-content-region" className="main-content col-xs-12">
                                <div className="region region-content">
                                    <div id="block-system-main" className="block block-system">
                                        <div className="content">
                                            <div className="node node-page clearfix">
                                                <div className="content">
                                                    <p>This calendar is a list of events that are being run by The Branch</p>
                                                    <Iframe frameBorder="0" url="https://thebranch.elvanto.com.au/calendar_embed/?c[]=75ea72dd-4e6d-11e4-a869-0622f7688f16&c[]=b5253e84-3fa7-11e3-963d-e9c9319b7782&ca[]=services&fd=s&l[]=b9590fcf-43d8-4201-aebd-c7f4acd55882&l[]=042ef33c-9ff7-11e6-bd2b-0219ad55c99b" marginWidth="0" width="100%" height="1000px" position="relative" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>




                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Calendar;

import React from 'react';
import FacebookFeed from './FacebookFeed'

const FacebookFeedContainer = () => {
  return (

    <div className="col-md-4 col-xs-12">
      <div className="region region-content-2-3">

        <div className="block block-block">

          <h2 className="header-lightBlue">Facebook Feed</h2>

          <div className="content">
            <FacebookFeed />

          </div>
        </div>
      </div>
    </div>
  );
};

export default FacebookFeedContainer;
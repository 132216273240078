/* eslint-disable */
import React, { Component } from "react";
import { IoIosPerson } from "react-icons/io";
import { FaYoutube } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";

class Footer extends Component {
	render() {
		return (
			<section>
				{/* Footer Columns region */}
				<div
					id="footer-columns-region"
					className="footer-columns region-30 block-30 text-color-light"
					style={{ backgroundColor: "#967451", color: "#f6ece3" }}
				>
					<div className="container">
						<div className="row">
							<div id="footer-third-column-region" className="footer-third-column col-xs-12 col-md-4">
								<div className="region region-footer-third-column">
									<div className="block block-block">
										<div className="content">
											<div className="footer-phone text-center">
												<span style={{ fontSize: "32px" }}>
													<FiFacebook />
												</span>
												<span style={{ fontSize: "32px" }}>
													<FaYoutube />
												</span>
												<p>
													<a
														className="footer-link"
														href="https://www.facebook.com/thebranch.org.au/"
														target="_blank"
														rel="noreferrer noopener"
													>
														facebook.com/thebranch.org.au
													</a>
												</p>
												<p>
													<a
														className="footer-link"
														href="https://www.youtube.com/channel/UCEMq7Z0Gv74aYt5bRr1PIcg"
														target="_blank"
														rel="noreferrer noopener"
													>
														The Branch on YouTube
													</a>
												</p>
											</div>{" "}
										</div>
									</div>{" "}
								</div>
							</div>{" "}
							{/* /footer-third-column-region */}
							<div id="footer-second-column-region" className="footer-second-column col-xs-12 col-md-4">
								<div className="region region-footer-second-column">
									<div id="block-block-8" className="block block-block">
										<div className="content">
											<div className="footer-mail text-center">
												<i className="icon ion-ios7-email-outline size-32 margin-bottom-20"></i>
												<p>
													<a
														className="footer-link"
														title="Get in Touch"
														href="https://thebranch.elvanto.com.au/form/8a95e7d4-4b16-11e3-963d-e9c9319b7782"
														target="_blank"
														rel="noreferrer noopener"
													>
														Get in Touch
													</a>
												</p>
											</div>{" "}
											{/* /footer-mail */}{" "}
										</div>
									</div>{" "}
								</div>
							</div>{" "}
							{/* /footer-second-column-region */}
							<div id="footer-first-column-region" className="footer-first-column col-xs-12 col-md-4">
								<div className="region region-footer-first-column">
									<div className="block block-block">
										<div className="content">
											<div className="footer-address text-center">
												<IoIosPerson size={32} />
												<p>
													<a
														className="footer-link"
														href="https://thebranch.elvanto.com.au/login/"
														target="_blank"
														rel="noreferrer noopener"
													>
														The Branch Hub
													</a>
												</p>
												<p>
													<a
														className="footer-link"
														href="https://branch.libib.com/"
														target="_blank"
														rel="noreferrer noopener"
													>
														Church Library
													</a>
												</p>
											</div>{" "}
											{/* /footer-address */}{" "}
										</div>
									</div>{" "}
								</div>
							</div>{" "}
							{/* /footer-first-column-region */}
						</div>{" "}
						{/* /row */}
					</div>{" "}
					{/* /container */}
				</div>{" "}
				{/* /footer-columns-region */}
				{/* /Footer Columns region */}
				{/* Footer region */}
				<footer className="region-10 block-10 bg-color-grayDark1 text-color-light">
					<div className="container">
						<div className="row">
							<div
								id="footer-left-region"
								className="footer-left region-bottom-sm-0 text-center-sm footer_left col-xs-12 col-md-6"
							>
								<div className="region region-footer-left">
									<div id="block-block-10" className="block block-block">
										<div className="content">
											<div className="copyright">
												<p>
													Website built and maintained by{" "}
													<a className="footer-link" href="http://newfrontdoor.org">
														New Front Door
													</a>
												</p>
											</div>{" "}
											{/* /copyright */}{" "}
										</div>
									</div>{" "}
								</div>
							</div>{" "}
							{/* /footer-left-region */}
							<div
								id="footer-right-region"
								className="footer-right region-top-sm-0 text-right text-center-sm footer_right col-xs-12 col-md-6"
							>
								<div className="region region-footer-right">
									<div className="block block-block">
										<div className="content">
											{/*  <div className="social-networks-footer">
                       <a href="https://www.facebook.com/NewFrontDoorIT/"><i className="icon ion-social-facebook"></i></a>
                        <a href="https://twitter.com/NewFrontDoorIT"><i className="icon ion-social-twitter"></i></a>
                        <a href="mailto:contactus@newfrontdoor.org"><i className="icon ion-email"></i></a>
                    </div>*/}{" "}
											{/* /social-networks-footer */}{" "}
										</div>
									</div>{" "}
								</div>
							</div>{" "}
							{/* /footer-right-region */}
						</div>{" "}
						{/* /row */}
					</div>{" "}
					{/* /container */}
				</footer>
				{/* /Footer region */}
				{/* Back to top button  */}
				<div id="back-to-top">
					<i className="ion-ios7-arrow-up"></i>
				</div>
				{/* End of Back to top button */}
			</section>
		);
	}
}

export default Footer;
